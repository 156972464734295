import { Link } from "react-router-dom"

const Settings = () => {
  return (
    <div>
        <div>There are no settings yet.</div>
        <Link to="/">Go Back</Link>
    </div>
  )
}

export default Settings